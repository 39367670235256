import React, { useState } from "react";
import { Link } from "@reach/router";
import profileIcon from "../../assets/images/profile-icon.png";
import Button from "../common/Button";

const Nav = (params) => {
  const [expand, toggleExpand] = useState(true);
  const [navMenu, toggleNavMenu] = useState(false);

  const isActive = ({ isCurrent }) => {
    return isCurrent
      ? { className: "nav-link active" }
      : { className: "nav-link" };
  };

  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
      ? { className: "nav-link active" }
      : { className: "nav-link" };
  };

  return (
    <nav className="navbar navbar-expand-sm navbar-light">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand" />
        <button
          className="navbar-toggler"
          onClick={() => toggleExpand(!expand)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={
            "navbar-collapse justify-content-between " +
            (expand ? "collapse" : "collapsed")
          }
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" getProps={isActive}>
                Envelopes
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/summary" getProps={isPartiallyActive}>
                Summary
              </Link>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Button
                className="btn-profile"
                action={() => toggleNavMenu(!navMenu)}
              >
                <img src={profileIcon} alt="profile" />
              </Button>
            </li>
          </ul>
          <ul
            className={
              "navbar-nav toggle-nav-menu " + (navMenu ? "show" : "hide")
            }
          >
            <li className="nav-item">
              <Link to="/profile" className="nav-link">
                Profile
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/logout" className="nav-link">
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Nav;
