import { Link } from "@reach/router";
import React from "react";
import CircleGraph from "./circleGraph";

const SummaryCard = ({ props, link, linkText }) => {
  return (
    <div className="summary-card">
      <div className="card pt-3">
        <CircleGraph props={props.values} />
        <div className="card-body">
          <h5 className="card-title">{props.name}</h5>
          <p className="card-text">{props.desc}</p>
          {link && (
            <Link to={link} className="btn btn-light float-end">
              {linkText}
            </Link>
          )}
        </div>
        <div
          className={
            "summary-bottom text-center bg-" +
            (props.amount >= 0 ? "success" : "danger")
          }
        >
          <h3 className="mt-1">${props.amount}</h3>
        </div>
      </div>
    </div>
  );
};
export default SummaryCard;
