import axios from "axios";
import session from "./session";
import { toast } from "react-toastify";

const testLogin = {
  request: "user/login",
  data: { email: "tommy@levelup.enterprises", password: "password" },
};

// Root url
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.common = {
  "Content-type": "application/json; charset=UTF-8",
};

//! -----------------------------------------
// //# Handle 400 & 500 errors
axios.interceptors.response.use(null, (error) => {
  if (error.response.status >= 400) {
    const { response } = error;
    response.data.message && toast.error(response.data.message);
    if (error.response.status === 503) {
      session.remove("token");
    }
  }
  return Promise.reject({ data: error });
});
//! -----------------------------------------

//# -----------------------------------------
//# Login
export const login = async (creds) => {
  try {
    return await getToken(creds);
  } catch (error) {
    console.log(error);
  }
};
//# -----------------------------------------

//? -----------------------------------------
//# Get & set Token
export const request = async (path, json) => {
  try {
    return await getToken()
      .then((token) =>
        axios.post(path, json, {
          headers: { Authorization: `Bearer ${token}` },
        })
      )
      .catch((e) => {
        console.log(e);
        if (e.data.response.status === 503) {
          session.remove("token");
        }
      });
  } catch (error) {
    console.log(error);
  }
};
//? -----------------------------------------

//* -----------------------------------------
//# Get & set Token
export const getToken = async (creds = null) => {
  try {
    let token = session.get("token") || null;
    if (!token && creds) {
      const res = await axios.post("auth/", raw(creds));
      console.log(res);
      if (res.status === 200) {
        token = res.data;
        session.set("token", token);
      }
    }
    return token;
  } catch (error) {
    console.log(error);
  }
};
//* -----------------------------------------

//* -----------------------------------------
//# Get all envelopes
export const getAllEnvelopes = async () => {
  try {
    const res = await request(
      "get/",
      raw({
        request: "envelopes/all",
      })
    );
    return res.status === 200 ? res.data : [{}];
  } catch (error) {
    console.log(error);
  }
};
//* -----------------------------------------

//* -----------------------------------------
//# Get envelope by id
export const getEnvelope = async (id) => {
  try {
    const res = await request(
      "get/",
      raw({
        request: "envelopes/id",
        id: id,
      })
    );
    return res.status === 200 ? res.data : "Error!";
  } catch (error) {
    console.log(error);
  }
};
//* -----------------------------------------

//* -----------------------------------------
//# Get all tags
export const getInitial = async () => {
  try {
    const res = await request(
      "get/",
      raw({
        request: "envelopes/new",
      })
    );
    return res.status === 200 ? res.data : [{}];
  } catch (error) {
    console.log(error);
  }
};
//* -----------------------------------------

//* -----------------------------------------
//# Get all tags
export const getTags = async () => {
  try {
    const res = await request(
      "get/",
      raw({
        request: "envelopes/tags",
      })
    );
    return res.status === 200 ? res.data : [{}];
  } catch (error) {
    console.log(error);
  }
};
//* -----------------------------------------

//* -----------------------------------------
//# Get all envelopes
export const createEnvelopes = async (values) => {
  try {
    const res = await request(
      "post/",
      raw({
        request: "envelopes/add",
        data: values,
      })
    );
    console.log(res);
  } catch (error) {
    console.log(error);
  }
};
//* -----------------------------------------

//* -----------------------------------------
//# Get summary
export const getSummary = async (values) => {
  try {
    const res = await request(
      "get/",
      raw({
        request: "summary/all",
        data: values,
      })
    );
    return res.status === 200 ? res.data : [{}];
  } catch (error) {
    console.log(error);
  }
};
//* -----------------------------------------

//* -----------------------------------------
//# Get account summary
export const getAccount = async (values) => {
  try {
    const res = await request(
      "get/",
      raw({
        request: "summary/account",
        data: values,
      })
    );
    console.log(res.data);
    return res.status === 200 ? res.data : [{}];
  } catch (error) {
    console.log(error);
  }
};
//* -----------------------------------------

//* -----------------------------------------
//# Get user profile
export const getProfile = async () => {
  try {
    const res = await request(
      "get/",
      raw({
        request: "users/profile",
      })
    );
    console.log(res.data);
    return res.status === 200 ? res.data : [{}];
  } catch (error) {
    console.log(error);
  }
};
//* -----------------------------------------

//? -----------------------------------------
//# Update total
export const editEnvelopeTotal = async (values) => {
  try {
    const res = await request(
      "post/",
      raw({
        request: "envelopes/edit/total",
        data: values,
      })
    );
    return res.status === 200 ? res.data : "Error!";
  } catch (error) {
    console.log(error);
  }
};
//? -----------------------------------------

//? -----------------------------------------
//# Edit Envelope
export const editEnvelope = async (values) => {
  try {
    const res = await request(
      "post/",
      raw({
        request: "envelopes/edit",
        data: values,
      })
    );
    return res.status === 200 ? res.data : "Error!";
  } catch (error) {
    console.log(error);
  }
};
//? -----------------------------------------

//? -----------------------------------------
//# Pin Envelope
export const pinEnvelope = async (values) => {
  try {
    const res = await request(
      "post/",
      raw({
        request: "envelopes/pin",
        data: values,
      })
    );
    return res.status === 200 ? res.data : "Error!";
  } catch (error) {
    console.log(error);
  }
};
//? -----------------------------------------

//? -----------------------------------------
//# Add Envelope
export const addEnvelope = async (values) => {
  try {
    const res = await request(
      "post/",
      raw({
        request: "envelopes/add",
        data: values,
      })
    );
    return res.status === 200 ? res.data : "Error!";
  } catch (error) {
    console.log(error);
  }
};
//? -----------------------------------------

//? -----------------------------------------
//# Update password
export const updatePassword = async (values) => {
  try {
    const res = await request(
      "post/",
      raw({
        request: "users/password",
        data: values,
      })
    );
    return res.status === 200 ? res.data : "Error!";
  } catch (error) {
    console.log(error);
  }
};
//? -----------------------------------------

//! -----------------------------------------
//# Remove Envelope
export const removeEnvelope = async (values) => {
  try {
    const res = await request(
      "delete/",
      raw({
        request: "envelope",
        data: values,
      })
    );
    return res.status === 200 ? res.data : "Error!";
  } catch (error) {
    console.log(error);
  }
};
//! -----------------------------------------

const raw = (obj) => {
  return JSON.stringify(obj);
};
