import React, { useReducer } from "react";
import jwt from "jwt-decode";
import session from "../services/session";

let reducer = (user, newUser) => {
  return { ...user, ...newUser };
};

const getUser = () => {
  try {
    const user = session.get("token") || null;
    if (user) {
      let time = jwt(user);

      time = new Date(time.exp);
      time < new Date() && window.location.replace("/logout");

      return jwt(user);
    } else return {};
  } catch (e) {
    console.log(e);
  }
};

const UserContext = React.createContext();

const UserProvider = (props) => {
  const [user, updateUser] = useReducer(reducer, getUser());

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
