import React from "react";

const Table = ({ props }) => {
  const buildHeaders = () => {
    let columns = [];
    Object.entries(props[0]).forEach((v, i) => {
      v[0] !== "id" &&
        columns.push(
          <th scope="col" key={i}>
            {v[0]}
          </th>
        );
    });

    return (
      <thead className="table-header">
        <tr>{columns}</tr>
      </thead>
    );
  };

  const buildRows = () => {
    let rows = [];
    Object.values(props).forEach((r, i) => {
      let data = [];
      Object.entries(r).forEach((v, i) => {
        v[0] !== "id" && data.push(<td key={i}>{v[1]}</td>);
      });
      rows.push(
        <tr className={Number(r.Amount) >= 0 ? "deposit" : ""} key={i}>
          {data}
        </tr>
      );
    });

    return <tbody>{rows}</tbody>;
  };

  return (
    // <table className="table table-striped table-hover">
    <div className="shadow-box-table">
      <table className="table">
        {buildHeaders()}
        {buildRows()}
      </table>
    </div>
  );
};
export default Table;
