import React from "react";
import Button from "./Button";

const Popup = ({ props }) => {
  return (
    <div className="popup">
      <div className="shadow-box">
        <Button className="btn-close" action={() => props.closePopup()} />
        {props.title && <h3>{props.title}</h3>}
        {props.content && <p>{props.content}</p>}
        {props.confirm && (
          <div className="flex-wrapper">
            <Button
              className="btn-warning "
              text={props.confirmYes ? props.confirmYes : "Yes"}
              action={(e) => props.confirmResponse(true)}
            />
            <Button
              className="btn-light dashed"
              text={props.confirmNo ? props.confirmNo : "No"}
              action={(e) => props.confirmResponse(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default Popup;
