import React from "react";
import { Link } from "@reach/router";

const InfoCard = ({ props, link, linkText, active }) => {
  return (
    <div className={"card info" + (active ? " active" : "")}>
      <div className="flex-wrapper w-100">
        <div className="card-body">
          <h5 className="card-title">{props.name}</h5>
          {props.icon && <h1 className="icon">{props.icon}</h1>}
          <p className="card-text">
            <ul>
              {props.desc.map((v) => (
                <li>{v}</li>
              ))}
            </ul>
          </p>
          {props.price && (
            <>
              <hr />
              <h6 className="card-title">Price</h6>
              <p className="card-text">
                <ul className="no-decoration">
                  {props.price.map((v) => (
                    <li>{v}</li>
                  ))}
                </ul>
              </p>
            </>
          )}
          {link && (
            <Link to={link} className="btn btn-light float-end">
              {linkText}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
export default InfoCard;
