import React, { useEffect, useState } from "react";
import { ResponsivePieCanvas } from "@nivo/pie";

const PieChart = ({ data, onClick }) => {
  const [showLabels, toggleShowLabels] = useState(true);

  useEffect(() => {
    const width = window.screen.width;
    width < 500 ? toggleShowLabels(false) : toggleShowLabels(true);
  }, []);

  window.addEventListener("resize", () => {
    const width = window.screen.width;
    width < 500 ? toggleShowLabels(false) : toggleShowLabels(true);
  });

  return (
    <ResponsivePieCanvas
      data={data}
      onClick={(e) => onClick(e.id)}
      margin={
        showLabels
          ? { top: 30, right: 30, bottom: 30, left: 30 }
          : { top: 0, right: 0, bottom: 0, left: 0 }
      }
      padAngle={1}
      activeOuterRadiusOffset={8}
      colors={{ scheme: "paired" }}
      borderColor={{ from: "color", modifiers: [["darker", 0.6]] }}
      enableArcLinkLabels={showLabels}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabel={(e) => e.value + "%"}
      arcLabelsRadiusOffset={0.75}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor="#333333"
      tooltip={({ datum: { id, label, color } }) => (
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            padding: 5,
            background: "#fff",
            borderRadius: ".25rem",
            boxShadow: "8px 10px 2px -4px #7777774d",
          }}
        >
          <div
            style={{
              width: 12,
              height: 12,
              marginRight: 6,
              backgroundColor: color,
            }}
          ></div>
          {id}: ${label}
        </div>
      )}
      legends={[]}
    />
  );
};
export default PieChart;
