import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import { addEnvelope, getInitial } from "../../services/http";
import { formatNumber } from "../../services/utilities";
import { Link } from "@reach/router";
import { toast } from "react-toastify";
import config from "../../config";
import _ from "lodash";
import Nav from "../common/nav";
import Footer from "../common/footer";

import CircleGraph from "../common/circleGraph";
import Button from "../common/Button";
import Input from "../common/form-input";
import Textarea from "../common/form-textarea";
import Select from "../common/form-select";
import SelectColor from "../common/form-select-color";

const NewEnvelope = () => {
  const [envelope, setEnvelope] = useState(config.newEnvelope);
  const [accounts, setAccounts] = useState(null);
  const [tags, setTags] = useState(null);
  const [showNewTag, toggleShowNewTag] = useState(false);
  const [showNewAccount, toggleShowNewAccount] = useState(false);
  const [tagColors, setTagColors] = useState(config.tagOptions);
  const [errors, setErrors] = useState({});
  const [addTotal, setAddTotal] = useState("");
  const [addGoal, setAddGoal] = useState("");
  const [percentage, setPercentage] = useState(0);

  useEffect(() => updateDropdowns(), []);

  const updateDropdowns = async () => {
    const { data } = await getInitial();
    console.log(data);
    data.tags && data.tags.push({ name: "Create new", value: "showOther" });
    data.accounts &&
      data.accounts.push({ name: "Create new", value: "showOther" });
    setAccounts(data.accounts);
    setTags(data.tags);
  };

  // Format value to 2 decimals
  const formatValues = (num, type) => {
    if (type === "total") {
      num = formatNumber(num, addTotal);
      num && setAddTotal(num);
      addGoal > 0 && setPercentage((num / addGoal) * 100);
    }
    if (type === "goal") {
      num = formatNumber(num, addGoal);
      num && setAddGoal(num);
      num > 0 && setPercentage((addTotal / num) * 100);
    }
  };

  const updateEnvelope = (e) => {
    let update = envelope;
    switch (e.name) {
      case "name":
        update.name = e.value;
        break;
      case "account":
        update.account = e.value;
        break;
      case "desc":
        update.desc = e.value;
        break;
      case "source":
        update.defaultMethod = e.value;
        break;
      case "tag":
        update.tag.name = e.value;
        break;
      case "current-tags":
        if (e.value === "showOther") {
          toggleShowNewTag(true);
          update.tag.color = config.tagOptions[0].value;
        } else {
          toggleShowNewTag(false);
          update.tag.name = e.value;
          const tag = tags.find((v) => v.value === e.value);
          update.tag.color = tag.color;
        }
        break;
      case "current-accounts":
        if (e.value === "showOther") {
          toggleShowNewAccount(true);
        } else {
          toggleShowNewAccount(false);
          update.account = e.value;
        }
        break;
      case "tag-color":
        update.tag.color = e.value;
        break;
      default:
        return;
    }
    setEnvelope({ ...envelope, ...update });
  };

  // Save envelope to db
  const save = async () => {
    setErrors({});
    const form = envelope;

    // Add state values
    form.values.goal = addGoal;
    form.values.total = addTotal;

    //! Required
    if (form.name === "") {
      setErrors({ ...errors, name: true });
      return;
    }
    const data = await addEnvelope(form);
    console.log(data);
    data && toast.success(data.message);
    navigate(-1);

    // Reset
    const reset = config.newEnvelope;
    reset.name = "";
    setEnvelope(reset);
    setAddTotal("");
    setAddGoal("");
  };

  return (
    <>
      <Nav />
      <section>
        {!_.isEmpty(envelope) && (
          <div className="container new-envelope">
            <div className="flex-wrapper">
              <Input
                required={true}
                error={errors.name}
                value={envelope.name && envelope.name}
                placeholder="Groceries"
                onChange={(e) => updateEnvelope(e)}
                className="mw-150"
                label="Envelope name"
                name="name"
              />
              <div className="flex m-space edit-mobile-wrapper mt-4">
                <Button className="btn-edit btn-success" action={() => save()}>
                  Add new envelope
                </Button>
                <Link className="btn btn-close mt-2" title="Cancel" to="/" />
              </div>
            </div>
            <hr />
            <div className="flex-wrapper start no-wrap mt-5">
              <div className="mr-auto fg-1 circle-graph-wrapper">
                <CircleGraph
                  props={{
                    goal: addGoal,
                    total: addTotal,
                    percentage: percentage,
                  }}
                  showPercent={true}
                />
              </div>
              <form className="fg-3 mb-5">
                <div className="flex m-space">
                  <Select
                    label="Tags"
                    onChange={(e) => updateEnvelope(e)}
                    name="current-tags"
                    options={tags && tags}
                  />
                  {showNewTag && (
                    <>
                      <Input
                        value={envelope.tag.name}
                        placeholder="Food"
                        onChange={(e) => updateEnvelope(e)}
                        className="mw-150"
                        label="New tag name"
                        name="tag"
                      />
                      <SelectColor
                        defaultValue={envelope.tag.color}
                        options={tagColors}
                        onChange={(e) => updateEnvelope(e)}
                        className="mw-150 mt-2"
                        name="tag-color"
                      />
                    </>
                  )}
                </div>
                <h5>Description</h5>
                <Textarea
                  value={envelope.desc && envelope.desc}
                  placeholder="Add a description of this envelope"
                  name="desc"
                  onChange={(e) => updateEnvelope(e)}
                />
                <h5>Amounts</h5>
                <div className="flex-wrapper m-space mb-5 shadow-box">
                  <Input
                    value={addGoal}
                    placeholder="$100.00"
                    onChange={(e) => formatValues(e.value, "goal")}
                    label="Goal"
                    name="goal"
                    help="Desired goal to reach."
                  />
                  <Input
                    value={addTotal}
                    placeholder="$20.00"
                    onChange={(e) => formatValues(e.value, "total")}
                    label="Total"
                    name="total"
                    help="Current amount in envelope."
                  />
                </div>

                <h5>Default methods</h5>
                <div className="flex-wrapper m-space mb-5 shadow-box">
                  <Select
                    label="Default deposit method"
                    defaultValue={
                      config.defaultDepositMethod && config.defaultDepositMethod
                    }
                    onChange={(e) => updateEnvelope(e)}
                    name="depositMethod"
                    options={config.methods}
                    help="Set default method for deposits."
                  />
                  <Select
                    label="Default withdraw method"
                    defaultValue={config.defaultMethod && config.defaultMethod}
                    onChange={(e) => updateEnvelope(e)}
                    name="method"
                    options={config.methods}
                    help="Set default method for withdrawals."
                  />
                </div>

                <h5>Account</h5>
                <div className="flex-wrapper m-space mb-5 shadow-box">
                  <Select
                    label="Linked account"
                    defaultValue={config.defaultMethod && config.defaultMethod}
                    onChange={(e) => updateEnvelope(e)}
                    name="current-accounts"
                    options={accounts && accounts}
                    help="Primary location of funds."
                  />
                  {showNewAccount && (
                    <Input
                      value={config.account}
                      placeholder="Savings / Cash"
                      onChange={(e) => updateEnvelope(e)}
                      label="Account name"
                      name="account"
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default NewEnvelope;
