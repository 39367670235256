import React from "react";

const CheckBox = ({
  name,
  type = "text",
  label,
  help,
  checked,
  disabled = false,
  className,
  onChange,
  defaultValue,
  ...rest
}) => {
  const handleOnChange = (e) => {
    onChange(e);
  };

  return (
    <>
      <div className="form-check">
        <input
          className={"form-check-input " + (className ? className : className)}
          type="checkbox"
          name={name}
          id={name}
          checked={checked}
          onChange={(e) => handleOnChange(e.target)}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        <label className="form-check-label" forhtml={name}>
          {label}
        </label>
      </div>
      {help && (
        <div id={name + "HelpBlock"} className="form-text">
          {help}
        </div>
      )}
    </>
  );
};
export default CheckBox;
