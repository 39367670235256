import React from "react";
import {
  format,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  lastDayOfMonth,
  subDays,
  addDays,
  getDaysInMonth,
} from "date-fns";

//# Date vars
const firstOfMonth = startOfMonth(new Date());
const endOfMonth = lastDayOfMonth(new Date());
const beginningOfWeek = startOfWeek(new Date());
const lastOfWeek = endOfWeek(new Date());
const middleOfMonth = Math.floor(getDaysInMonth(new Date()) / 2) - 1;
const dayOfMonth = format(new Date(), "dd");

//# Set split values
let split = {
  start: firstOfMonth,
  end: addDays(firstOfMonth, middleOfMonth),
};
if (dayOfMonth >= middleOfMonth) {
  split = {
    start: addDays(firstOfMonth, middleOfMonth),
    end: endOfMonth,
  };
}

const config = {
  defaultMethod: "Credit",
  defaultDepositMethod: "Bank",
  methods: [
    { name: "Credit", value: "Credit" },
    { name: "Bank", value: "Bank" },
    { name: "Cash", value: "Cash" },
  ],
  tagOptions: [
    { name: "blue", value: "primary" },
    { name: "grey", value: "secondary" },
    { name: "green", value: "success" },
    { name: "red", value: "danger" },
    { name: "yellow", value: "warning" },
    { name: "teal", value: "info" },
    { name: "black", value: "dark" },
  ],
  newEnvelope: {
    id: null,
    name: "",
    account: "",
    desc: "",
    tag: { name: "", color: "primary" },
    defaultSource: "",
    values: {
      goal: "",
      total: "",
      percentage: "",
    },
  },
  tiers: [
    {
      name: "Free",
      desc: ["5 envelopes", "3 months transaction history"],
    },
    {
      name: "Basic",
      desc: ["15 envelopes", "1 year transaction history"],
      price: ["$2.99 a month", "$32.50 a year (1 month free!)"],
    },
    {
      name: "Premium",
      desc: ["Unlimited envelopes", "Unlimited transaction history"],
      price: ["$4.99 a month", "$49.99 a year (2 months free!)"],
    },
  ],
  date: {
    range: [
      { name: "Monthly", value: "Monthly" },
      { name: "Split", value: "Split" },
      { name: "Bi-weekly", value: "Bi-weekly" },
      { name: "Weekly", value: "Weekly" },
      { name: "Custom", value: "Custom" },
    ],
    Monthly: {
      start: firstOfMonth,
      end: endOfMonth,
    },
    Split: split,
    "Bi-weekly": {
      start: subDays(beginningOfWeek, 7),
      end: lastOfWeek,
    },
    Weekly: {
      start: beginningOfWeek,
      end: lastOfWeek,
    },
    Custom: {
      start: beginningOfWeek,
      end: lastOfWeek,
    },
  },
};
export default config;
