import session from "./session";

/**
 * Take a number or string and convert to a value with
 *  2 decimal places.
 * @param {string} num value to format
 * @param {string} previous value before update
 * @returns
 */
export const formatNumber = (num, previous) => {
  if (!isNaN(num % 1)) {
    if (num.length > previous.length) {
      if (num.length < 2) {
        num = parseFloat(num / 100).toFixed(2);
      } else num = (Number(num) * 10).toFixed(2);
    } else {
      num = (Number(num) / 10).toFixed(2);
    }
    return num;
  }
};

/**
 * Set string to start with uppercase
 * @param {string} string
 * @returns {string}
 */
export const cpFirst = (string) => {
  if (string) {
    return string[0].toUpperCase() + string.slice(1);
  } else return "";
};

/**
 * Sort array object with sort by value
 * @param {object} obj
 * @param {string} value
 * @returns {object}
 */
export const sortObject = (obj, v, direction = "asc") => {
  const sorted = obj.sort((a, b) => {
    if (direction === "desc") {
      return a[v] - b[v];
    } else {
      return b[v] - a[v];
    }
  });
  return sorted;
};

/** ----------------------------------
 ** Start activity timer
 * -----------------------------------
 * Takes time value in milliseconds and
 *  starts timer. Timer is reset on
 *  mouse activity and key presses.
 *
 * @parm integer override - override default time (5 mins)
 */
export const timer = (override = null) => {
  try {
    let time = process.env.REACT_APP_ACTIVITY_TIMER;
    override && (time = override);

    let timer = setTimeout(() => logout(true), time);

    document.addEventListener("mousemove", () => {
      reset();
    });
    document.addEventListener("keypress", () => {
      reset();
    });

    function reset() {
      clearTimeout(timer);
      timer = setTimeout(() => {
        logout(true);
      }, time);
    }
  } catch (error) {
    console.log(error);
  }
};

export const logout = (inactive = null) => {
  if (inactive) {
    session.clear();
    session.set("inactive", "You have been logged out due to inactivity.");
  } else session.clear();
  window.location.replace("/login");
};
