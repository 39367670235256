import React from "react";

const Button = ({ text, className, action, children, ...rest }) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    action(e);
  };

  return (
    <button
      {...rest}
      className={"btn " + (className ? className : className)}
      onClick={(e) => handleOnClick(e)}
    >
      {text}
      {children}
    </button>
  );
};
export default Button;
