import React, { useEffect, useState, useRef } from "react";
import { navigate } from "@reach/router";
import {
  editEnvelope,
  editEnvelopeTotal,
  getEnvelope,
  removeEnvelope,
} from "../../services/http";
import { toast } from "react-toastify";
import { cpFirst, formatNumber } from "../../services/utilities";
import session from "../../services/session";
import config from "../../config";
import _ from "lodash";
import Nav from "../common/nav";
import Footer from "../common/footer";

import CircleGraph from "../common/circleGraph";
import Button from "../common/Button";
import Input from "../common/form-input";
import AdjustableInput from "../common/form-input-adjustable";
import Textarea from "../common/form-textarea";
import Select from "../common/form-select";
import SelectColor from "../common/form-select-color";
import Table from "../common/table";

const EditEnvelope = ({ id }) => {
  const ScrollRef = useRef(null);
  const [envelope, setEnvelope] = useState({});
  const [envelopeCopy, setEnvelopeCopy] = useState({});
  const [editable, toggleEditable] = useState(true); // disabled true
  const [showPosAdjuster, togglePosAdjuster] = useState(false);
  const [showNegAdjuster, toggleNegAdjuster] = useState(false);
  const [addTotal, setAddTotal] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const tagColors = config.tagOptions;

  useEffect(() => {
    id && returnEnvelope(id);
  }, [id]);

  const returnEnvelope = async (id) => {
    const { data } = await getEnvelope(id);
    console.log(data);
    setEnvelope(data);
  };

  const adjuster = (type = null) => {
    togglePosAdjuster(false);
    toggleNegAdjuster(false);
    setAddTotal("");
    setSubTotal("");
    if (type !== null) {
      type ? togglePosAdjuster(true) : toggleNegAdjuster(true);
      setTimeout(() => scrollToRef(), 100);
    }
  };

  // Format value to 2 decimals
  const formatValues = (num, type) => {
    if (type === "+") {
      if (!_.isEmpty(num)) {
        num = formatNumber(num, addTotal);
        num && setAddTotal(num);
      }
    }
    if (type === "-") {
      if (!_.isEmpty(num)) {
        num = formatNumber(num, subTotal);
        num && setSubTotal(num);
      }
    }
  };

  const add2total = () => {
    let update = envelope;

    // Add up values and set to 2 decimals
    update.values.total = (
      Number(update.values.total) + Number(addTotal)
    ).toFixed(2);

    if (update.values.goal) {
      update.values.percentage = (
        (update.values.total / update.values.goal) *
        100
      ).toFixed(2);
    }
    updateHistory(update, addTotal);
  };

  const subFromTotal = () => {
    let update = envelope;
    // Subtract values and set to 2 decimals
    update.values.total = (
      Number(update.values.total) - Number(subTotal)
    ).toFixed(2);

    if (update.values.goal) {
      update.values.percentage = (
        (update.values.total / update.values.goal) *
        100
      ).toFixed(2);
    }
    updateHistory(update, "-" + subTotal);
  };

  const scrollToRef = () => {
    ScrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const updateEnvelope = (e) => {
    let update = envelope;
    switch (e.name) {
      case "name":
        update.name = e.value;
        break;
      case "desc":
        update.desc = e.value;
        break;
      case "source":
        update.source = cpFirst(e.value);
        break;
      case "method":
        update.method = cpFirst(e.value);
        break;
      case "inflowMmethod":
        update.inflowMethod = cpFirst(e.value);
        break;
      case "goal":
        update.values.goal = e.value;
        break;
      case "total":
        update.values.total = e.value;
        break;
      case "tag":
        update.tag.name = e.value;
        break;
      case "tag-color":
        update.tag.color = e.value;
        break;
      default:
        return;
    }
    setEnvelope({ ...envelope, ...update });
  };

  const enableEdit = () => {
    toggleEditable(!editable);
    setEnvelopeCopy(_.cloneDeep(envelope));
  };

  const updateHistory = (update, amount) => {
    let date = new Date();
    date =
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();

    if (_.isEmpty(update.method)) {
      update.method = _.isEmpty(subTotal)
        ? update.defaultDepositMethod
        : update.defaultMethod;
    }

    update.history &&
      update.history.unshift({
        Date: date,
        Amount: amount,
        // total: amount,
        Source: !_.isEmpty(subTotal) && cpFirst(update.source),
        Method: update.method,
      });

    setEnvelope({ ...envelope, ...update });
    updateTotal(update, amount);
  };

  const updateTotal = async (update, amount) => {
    const edits = {
      id: update.id,
      amount: amount,
      total: update.values.total,
      source: !_.isEmpty(subTotal) && cpFirst(update.source),
      method: update.method,
    };
    const data = await editEnvelopeTotal(edits);
    console.log(data);
    setAddTotal("");
    setSubTotal("");
    togglePosAdjuster(false);
    toggleNegAdjuster(false);
    session.remove("envelopes");
    session.remove("all-envelopes");
  };

  // Save envelope to db
  const save = async () => {
    let edits = envelope;
    delete edits.history;
    // Save to db here
    const data = await editEnvelope(edits);
    console.log(data);
    data && toast.success(data.message);

    toggleEditable(!editable);
  };

  const cancelSave = () => {
    setEnvelope({ ...envelopeCopy });
    toggleEditable(!editable);
  };

  const remove = async () => {
    console.log(envelope);
    const data = await removeEnvelope({ id: envelope.id });
    console.log(data);
    data && toast.success(data.message);
    navigate(-1);
  };

  return (
    <>
      <Nav />
      <section>
        {!_.isEmpty(envelope) && (
          <div className="container envelope">
            <div className="shadow-box">
              {editable ? (
                <h1>{envelope.name}</h1>
              ) : (
                <Input
                  disabled={editable}
                  value={envelope.name && envelope.name}
                  onChange={(e) => updateEnvelope(e)}
                  className="mw-150"
                  label="Envelope name"
                  name="name"
                />
              )}
            </div>
            <div className="flex-wrapper start mt-5">
              <div className="circle-graph-wrapper fg-1">
                <CircleGraph props={envelope.values} showPercent={true} />
              </div>
              <form className="fg-3 mb-5">
                <div className="flex-wrapper">
                  {editable ? (
                    <h4 className="tag mb-4">
                      <span className={"badge bg-" + envelope.tag.color}>
                        {envelope.tag.name}
                      </span>
                    </h4>
                  ) : (
                    <div className="flex m-space">
                      <Input
                        value={envelope.tag.name}
                        onChange={(e) => updateEnvelope(e)}
                        className="mw-150"
                        label="Tag name"
                        name="tag"
                      />
                      <SelectColor
                        defaultValue={envelope.tag.color}
                        options={tagColors}
                        onChange={(e) => updateEnvelope(e)}
                        className="mw-150 mt-2"
                        name="tag-color"
                      />
                    </div>
                  )}
                  {editable ? (
                    <Button
                      className="btn-edit btn-light"
                      action={() => enableEdit()}
                    >
                      Edit envelope <span className="edit"></span>
                    </Button>
                  ) : (
                    <div className="flex m-space edit-mobile-wrapper">
                      <Button
                        className="btn-edit btn-success"
                        action={() => save()}
                      >
                        Save envelope
                      </Button>
                      <Button
                        className="btn-close mt-2"
                        title="Cancel"
                        action={() => cancelSave()}
                      />
                    </div>
                  )}
                </div>
                <h5>Description</h5>
                <Textarea
                  disabled={editable}
                  value={envelope.desc && envelope.desc}
                  placeholder={envelope.desc && envelope.desc}
                  name="desc"
                  onChange={(e) => updateEnvelope(e)}
                />
                <h5>Amounts</h5>
                <div className="flex-wrapper m-space shadow-box">
                  <Input
                    disabled={editable}
                    value={envelope.values.goal ? envelope.values.goal : ""}
                    placeholder={
                      envelope.values.goal && "$" + envelope.values.goal
                    }
                    onChange={(e) => updateEnvelope(e)}
                    className="mw-150"
                    label="Goal"
                    name="goal"
                    help="Desired goal to reach."
                  />
                  <div ref={ScrollRef} className="flex column">
                    <AdjustableInput
                      disabled={editable}
                      value={envelope.values && envelope.values.total}
                      placeholder={
                        envelope.values && "$" + envelope.values.total
                      }
                      adjustableClick={(v) => adjuster(v)}
                      onChange={(e) => updateEnvelope(e)}
                      className="mw-150"
                      label="Total"
                      name="total"
                      help="Current amount in envelope."
                    />
                    <div className="input-button-wrapper">
                      {showPosAdjuster && (
                        <>
                          <Input
                            help="Enter amount to add."
                            className="mw-150"
                            name="add-total"
                            value={addTotal}
                            onChange={(e) => formatValues(e.value, "+")}
                          />
                          <Button
                            text="Add"
                            className="btn-success"
                            action={() => add2total()}
                          />
                          <Button
                            className="btn-close"
                            action={() => adjuster()}
                          />
                        </>
                      )}
                      {showNegAdjuster && (
                        <>
                          <Input
                            type="number"
                            help="Enter amount to subtract."
                            className="mw-150"
                            name="sub-total"
                            value={subTotal}
                            onChange={(e) => formatValues(e.value, "-")}
                          />
                          <Button
                            text="Subtract"
                            className="btn-warning"
                            action={() => subFromTotal()}
                          />
                          <Button
                            className="btn-close"
                            action={() => adjuster()}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* Method & Source */}
                  <div>
                    {showPosAdjuster && (
                      <Select
                        label="Method"
                        defaultValue={
                          envelope.defaultDepositMethod &&
                          envelope.defaultDepositMethod
                        }
                        onChange={(e) => updateEnvelope(e)}
                        className="mw-150"
                        name="inflowMethod"
                        options={config.methods}
                      />
                    )}
                    {showNegAdjuster && (
                      <Select
                        label="Method"
                        defaultValue={
                          envelope.defaultMethod && envelope.defaultMethod
                        }
                        onChange={(e) => updateEnvelope(e)}
                        className="mw-150"
                        name="method"
                        options={config.methods}
                      />
                    )}
                    {showNegAdjuster && (
                      <div style={{ marginTop: "33px" }}>
                        <Input
                          label="Source"
                          help="Enter source of transaction."
                          className="mw-150"
                          name="source"
                          value={envelope.source && envelope.source}
                          onChange={(e) => updateEnvelope(e)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {!editable && (
                  <Button
                    className="btn-edit btn-danger mt-5 w-100"
                    action={() => remove()}
                  >
                    Delete envelope
                  </Button>
                )}
                {/* <div ref={ScrollRef}></div> */}
              </form>
            </div>
            {!_.isEmpty(envelope.history) && (
              <div>
                <h5>Transactions</h5>
                <Table props={envelope.history} />
              </div>
            )}
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default EditEnvelope;
