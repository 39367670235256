import React, { useState, useEffect } from "react";
import { getSummary } from "../../services/http";
import _ from "lodash";
import Nav from "../common/nav";
import Footer from "../common/footer";
import Card from "../common/card";
import SummaryCard from "../common/card-summary";

const Summary = (params) => {
  const [accountCards, setAccountCards] = useState(null);
  const [methodCards, setMethodCards] = useState(null);

  useEffect(() => {
    getSummaryData();
  }, []);

  const getSummaryData = async () => {
    const { data } = await getSummary();
    renderAccounts(data.accounts);
    renderMethods(data.history);
  };

  /**
   ** Render cards with provided data
   * @param {object} object envelope object
   */
  const renderAccounts = (object) => {
    setAccountCards(
      Object.values(object).map((values, i) => (
        <SummaryCard
          props={values}
          link={"/summary/" + values.name}
          linkText={"Explore"}
          key={i}
        />
      ))
    );
  };

  /**
   ** Render cards with provided data
   * @param {object} object envelope object
   */
  const renderMethods = (object) => {
    setMethodCards(
      Object.values(object).map((values, i) => <Card props={values} key={i} />)
    );
  };

  return (
    <>
      <Nav />
      <section>
        <div className="container">
          <div className="flex-wrapper mobile-apart shadow-box mb-4">
            <h1>Summary</h1>
          </div>
          <div>
            {!_.isEmpty(accountCards) && (
              <>
                <h2>Accounts</h2>
                <p>Here is an overview of your accounts.</p>
                <div
                  className={
                    "card-wrapper " + (accountCards.length < 4 ? "center" : "")
                  }
                >
                  {accountCards}
                </div>
              </>
            )}
            {!_.isEmpty(methodCards) && (
              <div className="mt-5">
                <h2>Transaction Methods</h2>
                <p>
                  This shows the inflow and outflow of funds for each
                  transaction method.
                </p>
                <div className="card-wrapper center">{methodCards}</div>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Summary;
