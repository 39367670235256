import React from "react";
import { Link } from "@reach/router";

const Footer = (params) => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          Budgeting
        </Link>
      </div>
    </footer>
  );
};
export default Footer;
