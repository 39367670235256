import React, { useState } from "react";
import { Link } from "@reach/router";
import { editEnvelopeTotal } from "../../services/http";
import { cpFirst, formatNumber } from "../../services/utilities";
import _ from "lodash";
import CircleGraph from "./circleGraph";
import Button from "./Button";
import Input from "./form-input";

const Card = ({ props, link, linkText, tagClick, pin, input1, input2 }) => {
  const [expandCard, setExpandCard] = useState(false);
  const [value1, setValue1] = useState(input1 ? input1.value : "");
  const [value2, setValue2] = useState(input2 ? input2.value : "");

  /** --------------------------------------
   ** Expand card on click
   * @param {object} e onclick target
   */
  const handleExpand = (e) => {
    if (input1 || input2) {
      const elem = e.target.className;
      !elem.startsWith("form-control") &&
        !elem.startsWith("btn") &&
        setExpandCard(!expandCard);
    }
  };

  /** --------------------------------------
   ** Format value to 2 decimals
   * @param {integer} num
   */
  const formatValues = (num) => {
    if (!_.isEmpty(num)) {
      num = formatNumber(num, value2);
      num && setValue2(num);
    }
  };

  const handleSubmit = async () => {
    const edits = {
      id: props.id,
      amount: "-" + value2,
      total: (props.values.total - value2).toFixed(2),
      source: !_.isEmpty(value1) && cpFirst(value1),
    };
    const data = await editEnvelopeTotal(edits);
    console.log(data);
    setValue1("");
    setValue2("");
    setExpandCard(false);
    input2.update({
      id: props.id,
      amount: "-" + value2,
      total: edits.total,
      percentage: ((edits.total / props.values.goal) * 100).toFixed(2),
    });
  };

  return (
    <div className="card" onClick={(e) => handleExpand(e)}>
      <div className="flex-wrapper w-100">
        {typeof props.pinned !== "undefined" && (
          <Button className="pin" action={() => pin(props.id)}>
            <span className={props.pinned ? "pinned" : ""}>&#x2605;</span>
          </Button>
        )}
        <Button
          className={"tag " + props.tag ? "" : "pt-3"}
          action={() => tagClick(props.tag.name)}
        >
          {props.tag && (
            <span className={"badge bg-" + props.tag.color}>
              {props.tag.name}
            </span>
          )}
        </Button>
      </div>
      <CircleGraph props={props.values} />
      <div className="card-body">
        <h5 className="card-title">{props.name}</h5>
        <p className="card-text">{props.desc}</p>
        {link && (
          <Link to={link} className="btn btn-light float-end">
            {linkText}
          </Link>
        )}
        <div className={"quick-expand" + (expandCard ? "" : " hide")}>
          {input1 && (
            <div className="card-input-wrapper">
              <Input
                label="Source"
                name={input1.name}
                value={value1}
                placeholder="Store"
                onChange={(e) => setValue1(e.value)}
              />
            </div>
          )}
          {input2 && (
            <div className="card-input-wrapper">
              <Input
                name={input2.name}
                value={value2}
                placeholder="$"
                onChange={(e) => formatValues(e.value)}
              />
              <Button
                text="&#x2212;"
                className="btn-warning"
                action={() => handleSubmit()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Card;
