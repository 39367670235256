import React, { useState, useEffect, useContext, useCallback } from "react";
import { UserContext } from "../../context/user";
import { getAccount } from "../../services/http";
import { sortObject } from "../../services/utilities";
import { format } from "date-fns";
import _ from "lodash";
import Nav from "../common/nav";
import Footer from "../common/footer";
import Table from "../common/table";
import PieChart from "../common/pie-chart";
import Button from "../common/Button";
import Select from "../common/form-select";
import config from "../../config";
import DatePicker from "react-datepicker";
import calendarIcon from "../../assets/images/calendar-icon.svg";
// Calendar styles
import "react-datepicker/dist/react-datepicker.css";
import session from "../../services/session";

const SummaryExplore = ({ account }) => {
  const { user } = useContext(UserContext);

  const [transactions, setTransactions] = useState(null);
  const [transactionsSrc, setTransactionsSrc] = useState(null);
  const [calendar, toggleCalendar] = useState(false);
  const [summary, setSummary] = useState(null);
  const [pieData, setPieData] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);
  // Calendar
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const setDates = useCallback(() => {
    let dates = session.get("dates") || {};

    if (_.isEmpty(dates)) {
      if (user.defaultDateRange !== "Custom") {
        dates.start = config.date[user.defaultDateRange].start;
        dates.end = config.date[user.defaultDateRange].end;
      } else {
        dates.start = new Date(user.dateRange.start);
        dates.end = new Date(user.dateRange.end);
      }
      session.set("dates", dates);
    } else {
      dates = {
        start: new Date(dates.start),
        end: new Date(dates.end),
      };
    }

    getAccountData({
      start: format(dates.start, "yyyy-MM-dd"),
      end: format(dates.end, "yyyy-MM-dd"),
    });

    // Update calendar dates
    setStartDate(new Date(dates.start));
    setEndDate(new Date(dates.end));
  }, []);

  useEffect(() => {
    setDates();
  }, [setDates]);

  const getAccountData = async (dates) => {
    const { data } = await getAccount({ account: account, dates: dates });
    updateData(data);
  };

  const updateData = (data) => {
    setSummaryData(data.summary);
    buildPieChart(data.transactions);
    setTransactions(data.transactions);
    setTransactionsSrc(data.transactions);
  };

  const setSummaryData = (data) => {
    setSummary(
      Object.entries(data).map((v, i) => (
        <div className="card" key={i}>
          <h3>{v[0]}</h3>
          <h3>${v[1]}</h3>
        </div>
      ))
    );
  };

  const buildPieChart = (data) => {
    let pie = [];
    // Split by source and count
    Object.values(data).forEach((v) => {
      if (v.Amount < 0) {
        v.Amount = Number(v.Amount);
        v.Source === null && (v.Source = "Other");
        pie[v.Source]
          ? (pie[v.Source] += v.Amount)
          : (pie[v.Source] = v.Amount);
      }
    });

    // Set values
    let total = 0;
    Object.values(pie).forEach((v) => {
      total += v;
    });

    let pieData = Object.entries(pie).map((v) => {
      const value = Math.round((v[1] / total) * 100);

      return {
        id: v[0],
        value: value,
        label: v[1].toFixed(2),
      };
    });

    setPieData(sortObject(pieData, "value"));
  };

  const handleDateRange = (range) => {
    setStartDate(config.date[range].start);
    setEndDate(config.date[range].end);

    getAccountData({
      start: format(config.date[range].start, "yyyy-MM-dd"),
      end: format(config.date[range].end, "yyyy-MM-dd"),
    });
  };

  const handleCalendar = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      const dates = {
        start: format(start, "yyyy-MM-dd"),
        end: format(end, "yyyy-MM-dd"),
      };
      getAccountData(dates);
      session.set("dates", dates);
    }
  };

  const handlePieClick = (source) => {
    let update = [];
    Object.values(transactionsSrc).forEach((v) => {
      v.Source === null && (v.Source = "Other");
      if (v.Source === source) {
        update.push(v);
      }
    });
    setTransactions(update);
    setClearFilter(true);
  };

  const clearFilterFn = () => {
    setTransactions(transactionsSrc);
    setClearFilter(false);
  };

  return (
    <>
      <Nav />
      <section>
        <div className="container">
          <div className="flex-wrapper mobile-apart">
            <h1>{account}</h1>
          </div>
          <hr />
          <div className="flex-wrapper date-range-container">
            <Select
              defaultValue={user.defaultDateRange}
              onChange={(e) => handleDateRange(e.value)}
              className="mw-150 card"
              name="dateRange"
              options={config.date.range}
              help="Select a date range"
            />
          </div>
          <div className="flex-wrapper">
            {!_.isEmpty(pieData) && (
              <div className="pie-chart-wrapper card">
                {clearFilter && (
                  <Button
                    className="btn-light"
                    text="Reset"
                    action={() => clearFilterFn()}
                  />
                )}
                <PieChart data={pieData} onClick={(e) => handlePieClick(e)} />
              </div>
            )}
            <Button
              className="btn-light mobile-calendar sticky"
              action={() => toggleCalendar(!calendar)}
            >
              <img src={calendarIcon} alt="calendar" />
            </Button>
            <div className={"date-wrapper" + (calendar ? " show" : "")}>
              <Button
                className="btn-close mobile-calendar"
                action={() => toggleCalendar(!calendar)}
              />
              <DatePicker
                selected={startDate}
                onChange={handleCalendar}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
            </div>
          </div>
          <div className="flex-wrapper mb-5 summary-totals">
            {summary && summary}
          </div>
          <div>
            {!_.isEmpty(transactions) ? (
              <div>
                <h5>Transactions</h5>
                <Table props={transactions} />
              </div>
            ) : (
              <div className="shadow-box text-center pt-3">
                <h5>No transaction history available for this date range</h5>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default SummaryExplore;
