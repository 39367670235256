import React from "react";

const CircleGraph = ({ props, showPercent }) => {
  var difference = "";
  props.percentage && (difference = props.percentage);

  const setColor = () => {
    if (props.goal) {
      if (difference >= 100) return "c100";
      if (difference >= 80) return "c80";
      if (difference >= 60) return "c60";
      if (difference >= 40) return "c40";
      if (difference >= 20) return "c20";
      if (difference >= 0) return "c0";
    }
  };
  const setGradient = () => {
    if (props.goal) {
      // let difference = (props.amount / props.goal) * 100;
      difference = 100 - difference;
      return {
        backgroundImage: `conic-gradient(#eae9e9 ${difference}%, transparent 0)`,
      };
    }
  };

  return (
    <div className="circle-graph">
      <div className={"circle " + setColor()} style={setGradient()}>
        <div className="mobile-line" style={{ width: difference + "%" }}></div>
        {props.goal ? (
          <div className="amount">
            <h3>${props.total}</h3>
            <small>
              of <b>${props.goal}</b> reached
            </small>
          </div>
        ) : (
          <h3 className="amount">${props.total}</h3>
        )}
      </div>
      {showPercent && props.percentage > 0 && (
        <div className="text-center mt-3">
          <h4>{Math.round(props.percentage)}% complete</h4>
        </div>
      )}
    </div>
  );
};
export default CircleGraph;
