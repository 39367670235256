import React, { useState, useEffect, useCallback } from "react";
import { getAllEnvelopes, pinEnvelope } from "../../services/http";
import { Link } from "@reach/router";
import session from "../../services/session";
import _ from "lodash";
import Nav from "../common/nav";
import Footer from "../common/footer";
import Card from "../common/card";
import Button from "../common/Button";

const Home = (params) => {
  const [envelopes, setEnvelopes] = useState(null);
  const [cards, setCards] = useState(null);
  const [favorites, setFavorites] = useState(null);
  const [tags, setTags] = useState(null);
  const [filteredSummary, setFilteredSummary] = useState(null);

  const updateFilters = useCallback(async (values = null) => {
    if (!values) {
      const { data } = await getAllEnvelopes();
      console.log(data);
      session.set("all-envelopes", data);
      renderEnvelopes(data);
      renderTagNav(data);
      setEnvelopes(data);
    } else {
      session.set("envelopes", values);
      renderEnvelopes(values);
    }
  }, []);

  useEffect(() => {
    updateFilters(envelopes);
  }, [updateFilters, envelopes]);

  /**
   ** Filter envelopes based on tag name
   * @param {string} tag name of tag
   */
  const filterTags = (tag) => {
    const envelopes = session.get("all-envelopes");
    const filter = Object.values(envelopes).filter(
      (values) => values.tag.name === tag
    );
    updateFilters(filter);
    filteredTotal(filter);
  };

  const filteredTotal = (object) => {
    let summary = {};
    Object.values(object).forEach((v) => {
      summary.tag = v.tag;
      summary.total = summary.total
        ? (Number(summary.total) + Number(v.values.total)).toFixed(2)
        : v.values.total;
    });
    setFilteredSummary({ ...summary });
  };

  const clearFiltered = () => {
    updateFilters(envelopes);
    setFilteredSummary(null);
  };

  const updateTotal = (card) => {
    const envelopes = session.get("all-envelopes");
    let update = Object.values(envelopes).filter(
      (values) => values.id === card.id
    );

    update[0].values.total = card.total;
    update[0].values.percentage = card.percentage;

    updateFilters({ ...envelopes, ...update });
  };

  /**
   ** Render cards with provided list
   * @param {object} object envelope object
   */
  const renderEnvelopes = (object) => {
    let cards = [];
    let favs = [];

    Object.values(object).forEach((values) => {
      if (!values.pinned) {
        cards.push(
          <Card
            props={values}
            link={"/envelope/" + values.id}
            linkText={"Manage"}
            key={values.id}
            tagClick={(e) => filterTags(e)}
            pin={(e) => setPinned(e)}
            input1={{
              name: "quickSource-" + values.id,
              value: "",
            }}
            input2={{
              name: "quickValue-" + values.id,
              value: "",
              update: (e) => updateTotal(e),
            }}
          />
        );
      }
    });

    Object.values(object).forEach((values) => {
      if (values.pinned) {
        favs.push(
          <Card
            props={values}
            link={"/envelope/" + values.id}
            linkText={"Manage"}
            key={values.id}
            tagClick={(e) => filterTags(e)}
            pin={(e) => setPinned(e)}
            input1={{
              name: "quickSource-" + values.id,
              value: "",
            }}
            input2={{
              name: "quickValue-" + values.id,
              value: "",
              update: (e) => updateTotal(e),
            }}
          />
        );
      }
    });

    setCards(cards);
    setFavorites(favs);
  };

  /**
   ** Render tags
   * @param {object} object envelope object
   */
  const renderTagNav = (envelopes) => {
    const tags = [];
    Object.values(envelopes).forEach((v) => {
      tags.push(v.tag);
    });
    // Filter out duplicates
    const filteredTags = tags.filter(
      (v, i, a) =>
        a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
    );
    setTags(
      Object.values(filteredTags).map((tag) => (
        <Button
          className="tag"
          key={tag.name}
          action={() => filterTags(tag.name)}
        >
          {tag && <span className={"badge bg-" + tag.color}>{tag.name}</span>}
        </Button>
      ))
    );
  };

  /**
   ** Handle Envelope pinning
   * @param {object} integer envelope id
   */
  const setPinned = async (id) => {
    const values = session.get("envelopes");
    await pinEnvelope({ id: id });
    if (values) {
      const index = Object.values(values).findIndex((v) => v.id === id);
      values[index].pinned = !values[index].pinned;
      setEnvelopes(values);
    }
  };

  return (
    <>
      <Nav />
      <section>
        <div className="container">
          <div className="flex-wrapper mobile-apart shadow-box mb-4">
            <h1>Envelopes</h1>
            <Link
              className="btn btn-light btn-add"
              title="Add envelope"
              to="/envelope/new/"
            >
              &#x2b;
            </Link>
          </div>
          {/* Tag navigation */}
          <div className="small">Quick filter</div>
          <div className="flex-wrapper start mb-3">
            {!_.isEmpty(tags) && tags}
          </div>
          {/* Sorted summary */}
          {filteredSummary && (
            <div className="flex-wrapper m-space mb-3">
              <div className={"info-tag badge bg-" + filteredSummary.tag.color}>
                {filteredSummary.tag.name}
              </div>
              <div className="info-tag badge total">
                Total ${filteredSummary.total}
              </div>
              <Button
                className="btn-close info-tag badge"
                action={() => clearFiltered()}
              />
            </div>
          )}
          {/* Pinned wrapper */}
          {!_.isEmpty(favorites) && !filteredSummary && (
            <>
              <div className="small">Favorites</div>
              <div className="card-wrapper">{favorites}</div>
              <hr />
            </>
          )}
          {/* Main wrapper */}
          {(!_.isEmpty(cards) || !_.isEmpty(favorites)) && (
            <div
              className={
                "card-wrapper " +
                (!favorites && cards.length < 4 ? "center" : "")
              }
            >
              {filteredSummary && favorites} {cards}
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
