/* eslint-disable import/no-anonymous-default-export */

/**--------------------------------------
 ** Set session
 * --------------------------------------
 * @param {string} name
 * @param {mixed} session
 * @returns {object}
 */
export function set(name, session) {
  try {
    if (typeof session === "object") {
      session = JSON.stringify(session);
    }
    return sessionStorage.setItem(name, session);
  } catch (error) {
    console.log(error);
  }
}

/**--------------------------------------
 ** Set local session
 * --------------------------------------
 * @param {string} name
 * @param {mixed} session
 * @returns {object}
 */
export function setLocal(name, session) {
  try {
    if (typeof session === "object") {
      session = JSON.stringify(session);
    }
    return localStorage.setItem(name, session);
  } catch (error) {
    console.log(error);
  }
}

/**--------------------------------------
 ** Get session values
 * --------------------------------------
 * @param {string} name
 * @returns {object}
 */
export function get(name) {
  try {
    let session = sessionStorage.getItem(name) || null;

    if (session !== null) {
      try {
        return JSON.parse(session);
      } catch (error) {
        return session;
      }
    }
    return session;
  } catch (error) {
    console.log(error);
  }
}
/**--------------------------------------
 ** Get local session values
 * --------------------------------------
 * @param {string} name
 * @returns {object}
 */
export function getLocal(name) {
  try {
    let session = localStorage.getItem(name) || null;

    if (session !== null) {
      try {
        return JSON.parse(session);
      } catch (error) {
        return session;
      }
    }
    return session;
  } catch (error) {
    console.log(error);
  }
}

/**--------------------------------------
 ** Remove session
 * --------------------------------------
 * @param {string} name
 * @returns {object}
 */
export function remove(name) {
  try {
    return sessionStorage.removeItem(name) || false;
  } catch (error) {
    console.log(error);
  }
}

/**--------------------------------------
 ** Remove local session
 * --------------------------------------
 * @param {string} name
 * @returns {object}
 */
export function removeLocal(name) {
  try {
    return localStorage.removeItem(name) || false;
  } catch (error) {
    console.log(error);
  }
}

/**--------------------------------------
 ** Remove all sessions
 * --------------------------------------
 * @param {string} name
 * @returns {object}
 */
export function clear() {
  try {
    return sessionStorage.clear() || false;
  } catch (error) {
    console.log(error);
  }
}

/**--------------------------------------
 ** Remove all local sessions
 * --------------------------------------
 * @param {string} name
 * @returns {object}
 */
export function clearLocal() {
  try {
    return localStorage.clear() || false;
  } catch (error) {
    console.log(error);
  }
}

export default {
  set,
  setLocal,
  get,
  getLocal,
  remove,
  removeLocal,
  clear,
  clearLocal,
};
