import React, { useState, useEffect, useContext } from "react";
import { getProfile, updatePassword } from "../../services/http";
import { cpFirst } from "../../services/utilities";
import { format } from "date-fns";
import { UserContext } from "../../context/user";
import { Link } from "@reach/router";
import config from "../../config";
import _ from "lodash";

import Nav from "../common/nav";
import Footer from "../common/footer";
import Input from "../common/form-input";
import Select from "../common/form-select";
import InfoCard from "../common/card-info";
import Button from "../common/Button";
import Popup from "../common/popup";

const Profile = (params) => {
  const { user } = useContext(UserContext);

  const [userValues, setUserValues] = useState({
    name: "",
    email: "",
    phone: "",
    defaultDateRange: user.defaultDateRange,
    dates: {
      start: format(new Date(), "yyyy-MM-dd"),
      end: format(new Date(), "yyyy-MM-dd"),
    },
  });
  const [passwords, setPasswords] = useState({
    password: "",
    verifyPassword: "",
  });
  const [subscriptionCards, setSubscriptionCards] = useState(null);
  const [showVerifyPass, setShowVerifyPass] = useState(false);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [dateHelp, setDateHelp] = useState("");
  const [errors, setErrors] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const dateOptions = {
    Monthly: "View transactions from the start to end of current month.",
    Split: "View transactions from the 1st to the 15th & the 15th to the 1st.",
    "Bi-weekly": "View transactions by every two weeks",
    Weekly: "View transactions by week",
    Custom: "Set custom range",
  };

  useEffect(() => {
    updateOptions(userValues.defaultDateRange);
    getProfileData();
  }, []);

  const getProfileData = async () => {
    const { data } = await getProfile();
    console.log(data);

    let update = {
      name: data.name || "",
      email: data.email || "",
      phone: data.phone || "",
    };

    if (user.defaultDateRange === "Custom") {
      update.dates = {
        start: format(new Date(user.customDates.start), "yyyy-MM-dd"),
        end: format(new Date(user.customDates.end), "yyyy-MM-dd"),
      };
    }

    setUserValues({ ...userValues, ...update });
    renderSubscriptions(config.tiers);
  };

  /**
   ** Render cards with subscription data
   * @param {object} object envelope object
   */
  const renderSubscriptions = (object) => {
    setSubscriptionCards(
      Object.values(object).map((values, i) => (
        <InfoCard props={values} key={i} />
      ))
    );
  };

  const updateOptions = (option) => {
    setDateHelp(dateOptions[option]);
  };

  const updateValues = (e) => {
    let update = userValues;
    switch (e.name) {
      case "name":
        update.name = cpFirst(e.value);
        break;
      case "email":
        update.email = e.value;
        break;
      case "phone":
        update.phone = e.value;
        break;
      case "defaultDateRange":
        update.defaultDateRange = e.value;
        updateOptions(e.value);
        break;
      case "customDatesStart":
        update.dates.start = e.value;
        break;
      case "customDatesEnd":
        update.dates.end = e.value;
        break;
      default:
        return;
    }
    setUserValues({ ...userValues, ...update });
    setShowUpdateBtn(true);
  };

  const updatePassword = (e) => {
    let update = passwords;
    switch (e.name) {
      case "password":
        update.password = e.value;

        // At least 8 characters
        if (!_.isEmpty(e.value)) {
          if (e.value.length < 8) {
            setShowVerifyPass(false);
            setErrors({
              ...errors,
              password: "Must be at least 8 characters.",
            });
          } else {
            setShowVerifyPass(true);
            setErrors({ ...errors, password: null });
          }
        } else {
          setErrors({ ...errors, password: null });
        }

        // Compare both fields
        if (!_.isEmpty(e.value) && !_.isEmpty(update.verifyPassword)) {
          e.value !== update.verifyPassword
            ? setErrors({ ...errors, password: "Passwords do not match!" })
            : setErrors({ ...errors, password: null });
        }
        break;
      case "verifyPassword":
        update.verifyPassword = e.value;
        // Compare both fields
        !update.password.startsWith(e.value)
          ? setErrors({ ...errors, password: "Passwords do not match!" })
          : setErrors({ ...errors, password: null });

        _.isEmpty(e.value) && setErrors({ ...errors, password: null });
        break;
      default:
        return;
    }
    setPasswords({ ...passwords, ...update });
  };

  const resetPassword = async () => {
    const { password, verifyPassword } = passwords;
    if (password !== verifyPassword) {
      setErrors({ ...errors, password: "Passwords do not match!" });
      return;
    }

    const data = await updatePassword({ password: userValues.password });
    console.log(data);
  };

  const closeAccount = (response) => {
    !response && setShowPopup(false);
  };
  console.log(userValues);
  return (
    <>
      <Nav />
      <section>
        <div className="container">
          <div className="mb-4">
            <h1>Profile</h1>
          </div>
          <hr />
          <h5>Personal information</h5>
          <div className="flex-wrapper m-space mb-5 mt-3">
            <Input
              label="Name"
              help="Update you name."
              className=""
              name="name"
              value={userValues.name}
              onChange={(e) => updateValues(e)}
            />
            <Input
              type="email"
              label="Email"
              help="Update you email address."
              name="email"
              value={userValues.email}
              onChange={(e) => updateValues(e)}
            />
            <Input
              type="phone"
              label="Phone"
              help={
                userValues.phone
                  ? "Update your phone number."
                  : "Add your phone number for security."
              }
              className="mw-150"
              name="phone"
              value={userValues.phone}
              onChange={(e) => updateValues(e)}
            />
          </div>
          <h5>Transaction history</h5>
          <p className="muted">
            Here you can specify your current pay period date ranges. This will
            help you see the correct data at the right time.
          </p>
          <div className="flex-wrapper m-space mb-4 mt-3">
            <div className="mw-250">
              <Select
                label="Default date range"
                help={dateHelp}
                defaultValue={userValues.defaultDateRange}
                onChange={(e) => updateValues(e)}
                className="mw-150 me-5"
                name="defaultDateRange"
                options={config.date.range}
              />
            </div>
            {userValues.defaultDateRange === "Custom" && (
              <div className="dashed-border flex wrap">
                <div className="mw-200 me-2">
                  <Input
                    type="date"
                    value={userValues.dates.start}
                    onChange={(e) => updateValues(e)}
                    className="mw-200"
                    label="Set start date"
                    name="customDatesStart"
                  />
                </div>
                <div className="mw-200">
                  <Input
                    type="date"
                    value={userValues.dates.end}
                    onChange={(e) => updateValues(e)}
                    className="mw-200"
                    label="Set end date"
                    name="customDatesEnd"
                  />
                </div>
              </div>
            )}
          </div>
          {showUpdateBtn && (
            <Button
              text="Update"
              className="btn-light dashed btn-lg py-2 px-5 mb-3"
            />
          )}
          <hr className="mb-5" />
          <h5>Password Reset</h5>
          <div className="flex-wrapper m-space mb-4 mt-3">
            <Input
              type="password"
              label="Password"
              help="Update your password."
              className=""
              name="password"
              value={userValues.password}
              onChange={(e) => updatePassword(e)}
              errorDetailed={errors.password}
            />
            {showVerifyPass && (
              <>
                <Input
                  type="password"
                  label="Verify password"
                  help="Re-enter your password."
                  className=""
                  name="verifyPassword"
                  value={passwords.verifyPassword}
                  onChange={(e) => updatePassword(e)}
                />
                <div
                  style={{
                    marginTop: 32,
                  }}
                >
                  <Button
                    className="btn-success"
                    text="Reset"
                    action={() => resetPassword()}
                  />
                </div>
              </>
            )}
          </div>
          <hr className="mb-5" />
          <div>
            <h5>Account Management</h5>
            <p className="muted mt-3">
              Change your subscription status at any time.
            </p>

            {subscriptionCards && (
              <div className="card-wrapper center mb-5">
                {subscriptionCards}
              </div>
            )}

            <Button
              className="btn-success"
              text="Update subscription"
              action={() => setShowPopup(true)}
            />
          </div>
          <div className="mt-5">
            <p className="muted">
              If you are unhappy with your experience or have an issue, please{" "}
              <Link to="/about/contact/">contact us</Link>. Otherwise if you
              wish to close your account, you can do so here. Your account will
              remain active until the end of your subscription.
            </p>
            <Button
              className="btn-danger"
              text="Close account"
              action={() => setShowPopup(true)}
            />
          </div>
        </div>
        {showPopup && (
          <Popup
            props={{
              title: "We hate to see you go!",
              content: "Are you sure you want to close your account?",
              closePopup: () => setShowPopup(false),
              confirm: true,
              confirmYes: "Yes, please close it!",
              confirmResponse: (e) => closeAccount(e),
            }}
          />
        )}
      </section>
      <Footer />
    </>
  );
};
export default Profile;
