import React from "react";

const Input = ({
  name,
  type = "text",
  label,
  help,
  value,
  placeholder,
  disabled = false,
  className,
  onChange,
  required,
  error,
  errorDetailed,
  ...rest
}) => {
  // const handleOnChange = (e) => {
  //   console.log(e);
  // };

  return (
    <div className="mb-3">
      <label
        forhtml={name}
        className={"form-label " + (required ? "required" : "")}
      >
        {label}
      </label>
      {error && <span className="error">Required</span>}
      <input
        type={type}
        name={name}
        id={name}
        value={!disabled ? value && value : ""}
        placeholder={placeholder && placeholder}
        className={"form-control " + (className ? className : className)}
        onChange={(e) => onChange(e.target)}
        disabled={disabled}
        {...rest}
      />
      {help && (
        <div id={name + "HelpBlock"} className="form-text">
          {help}
        </div>
      )}
      {errorDetailed && <div className="form-text error">{errorDetailed}</div>}
    </div>
  );
};
export default Input;
