import React from "react";

const Textarea = ({
  name,
  label,
  help,
  type,
  value,
  placeholder,
  onChange,
  disabled = false,
  ...rest
}) => {
  return (
    <div className="text-edit-wrapper">
      {label && (
        <label forhtml={name} className="form-label">
          {label}
        </label>
      )}
      <textarea
        name={name}
        id={name}
        value={!disabled ? value && value : ""}
        placeholder={placeholder && placeholder}
        className="form-control"
        aria-describedby={name + "HelpBlock"}
        onChange={(e) => onChange(e.target)}
        disabled={disabled}
        {...rest}
      ></textarea>
      {help && (
        <div id={name + "HelpBlock"} className="form-text">
          {help}
        </div>
      )}
    </div>
  );
};
export default Textarea;
