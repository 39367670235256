import React, { useContext, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Router } from "@reach/router";
import { UserContext } from "./context/user";
import { timer } from "./services/utilities";
import session from "./services/session";
import _ from "lodash";
// Routes
import Home from "./components/Envelopes/index";
import Summary from "./components/Summary/index";
import Login from "./components/Login/index";
import Logout from "./components/Logout/index";
import Envelopes from "./components/Envelopes/edit";
import NewEnvelope from "./components/Envelopes/new";
import SummaryExplore from "./components/Summary/explore";
import Profile from "./components/Users/index";

const App = () => {
  const { user } = useContext(UserContext);
  const token = session.get("token");
  process.env.NODE_ENV === "development" && console.log(user);

  useEffect(() => {
    !_.isEmpty(user) && timer(600000);
    const inactive = session.get("inactive");
    if (inactive) {
      toast(inactive, { autoClose: false, type: "warning" });
      session.remove("inactive");
    }
  }, [user]);

  return (
    <>
      <ToastContainer />
      <Router>
        {token && (
          <>
            <Home path="/" />
            <Summary path="/summary/" />
            <SummaryExplore path="/summary/:account" />
            <Envelopes path="/envelope/:id" />
            <NewEnvelope path="/envelope/new/" />
            <Profile path="/profile/" />
          </>
        )}
        <Logout path="/logout" />
        <Login path="/login" />
        <Login default />
      </Router>
    </>
  );
};

export default App;
