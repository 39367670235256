import React from "react";
import Button from "./Button";

const AdjustableInput = ({
  name,
  label,
  help,
  type,
  value,
  placeholder,
  adjustableClick,
  disabled = false,
  onChange,
  className,
  ...rest
}) => {
  return (
    <div className="mb-3">
      <label forhtml={name} className="form-label">
        {label}
      </label>
      <div className="flex-wrapper m-space">
        <input
          type="text"
          name={name}
          id={name}
          value={!disabled ? value && value : ""}
          placeholder={placeholder && placeholder}
          className={"form-control " + (className ? className : className)}
          onChange={(e) => onChange(e.target)}
          disabled={disabled}
          {...rest}
        />
        {disabled && (
          <>
            <Button
              text="&#x2b;"
              className="btn btn-primary fg-1"
              action={() => adjustableClick(true)}
            />
            <Button
              text="&#x2212;"
              className="btn btn-warning fg-1"
              action={() => adjustableClick(false)}
            />
          </>
        )}
      </div>
      {help && (
        <div id={name + "HelpBlock"} className="form-text">
          {help}
        </div>
      )}
    </div>
  );
};
export default AdjustableInput;
