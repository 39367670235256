import React, { useState } from "react";
import { login } from "../../services/http";
import { toast } from "react-toastify";
import session from "../../services/session";
import Button from "../common/Button";
import Input from "../common/form-input";
import CheckBox from "../common/form-checkbox";

const Login = () => {
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberDevice, setRememberDevice] = useState(false);

  const loginFn = async () => {
    setErrors({});

    //! Required
    if (email === "") {
      setErrors({ ...errors, email: true });
      return;
    }
    //! Required
    if (password === "") {
      setErrors({ ...errors, password: true });
      return;
    }

    if (rememberDevice) {
      session.setLocal("deviceID", new Date().getTime());
    }

    const data = await login({
      request: "user/login",
      data: {
        email: email,
        password: password,
      },
    });
    data && (window.location.href = "/");
  };

  return (
    <div className="login-page">
      <section className="login">
        <div className="container shadow-box">
          <h1>Login</h1>
          <form className="fg-3 mb-5 mt-4">
            <Input
              type="email"
              required={true}
              error={errors.email}
              value={email}
              placeholder="john.doe@email.com"
              onChange={(e) => setEmail(e.value)}
              label="Username"
              name="email"
            />
            <Input
              type="password"
              required={true}
              error={errors.password}
              value={password}
              onChange={(e) => setPassword(e.value)}
              label="Password"
              name="password"
            />
            <div className="flex-wrapper m-space align-center">
              <Button
                className="btn-light w-100 mw-150 dashed"
                action={() => loginFn()}
              >
                Login
              </Button>
              <CheckBox
                checked={rememberDevice}
                onChange={(e) => setRememberDevice(e.checked)}
                label="Remember this device?"
                name="keepLogin"
              />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Login;
