import React, { useState } from "react";

const SelectColor = ({
  name,
  type = "text",
  label,
  help,
  options,
  placeholder,
  disabled = false,
  className,
  onChange,
  defaultValue,
  ...rest
}) => {
  const [color, setColor] = useState(defaultValue);

  const handleOnChange = (e) => {
    setColor(e.value);
    onChange(e);
  };

  return (
    <div className="mb-3">
      <label forhtml={name} className="form-label">
        {label}
      </label>
      <select
        type={type}
        name={name}
        id={name}
        placeholder={placeholder && placeholder}
        className={
          "form-select color-select " +
          (color ? "bg-" + color : "") +
          " " +
          (className ? className : className)
        }
        aria-describedby={name + "HelpBlock"}
        onChange={(e) => handleOnChange(e.target)}
        defaultValue={defaultValue}
        disabled={disabled}
        {...rest}
      >
        {options &&
          Object.values(options).map((v) => (
            <option value={v.value} key={v.value}>
              {v.name}
            </option>
          ))}
      </select>
      {help && (
        <div id={name + "HelpBlock"} className="form-text">
          {help}
        </div>
      )}
    </div>
  );
};
export default SelectColor;
